import { Grid, Typography } from '@mui/material';
import { MobileScreenWrapper } from './MobileScreenWrapper';
import { useTranslation } from 'react-i18next';
import { Svgs } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { useMemo } from 'react';
import { PaymentApi } from '../../api/apis/PaymentApi';
import Footnote from '../settings/manage-subscription/Footnote';
import PlanActionGroupButtons from '../settings/manage-subscription/PlanActionGroupButtons';
import PlanCard from '../settings/manage-subscription/PlanCard';
import PlanFeaturesTable from '../settings/manage-subscription/PlanFeaturesTable';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { Loading } from '../../hooks/useLoading';
import {
  getCurrentPlan,
  getFootnoteElement,
  getRecentPlan,
} from '../settings/manage-subscription/functions';

export const MobileManageSubscriptionView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const subscriptionInit = Hooks.useSubscriptionInfoInitialized();
  const freeTrialStatus = Hooks.useFreeTrialStatus();
  const legalDocs = Hooks.useTermsAndConditions();
  const {
    threeNickelsProducts,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    orgPaysPricebook,
    orgDoesNotOfferFreePricebook,
  } = Hooks.useAvailableProducts();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const inAdviceFreeTrial = freeTrialStatus ? freeTrialStatus.active : false;
  const subscriptionsByStartDateDescending = useMemo(() => {
    if (!subscriptionInfoData) return [];
    return subscriptionInfoData.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [subscriptionInfoData]);
  const subscription = subscriptionsByStartDateDescending?.[0];
  const recentPlan = getRecentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);
  const userNeverSubscribedToExclusivePricebook =
    (!subscriptionInfoData || subscriptionInfoData.length === 0) &&
    orgDoesNotOfferFreePricebook &&
    !multipleProducts;

  const showSelectPlan = orgDoesNotOfferFreePricebook && multipleProducts;
  const currentPlan = getCurrentPlan(subscription, inAdviceFreeTrial, threeNickelsProducts);

  const planForTable = threeNickelsProducts
    ? threeNickelsProducts.filter(
        (p) => p.pricebook.pricebookName === recentPlan.pricebook.pricebookName
      )
    : [];

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  if (!subscriptionInit) {
    return <Loading />;
  }

  let footnoteCounter = 0;
  const footnoteTexts: string[] = [];

  const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
    footnoteCounter,
    recentPlan,
    inAdviceFreeTrial,
    subscription,
    freeTrialStatus,
    userNeverSubscribedToExclusivePricebook,
    true
  );
  if (footnoteText) {
    footnoteCounter = counter;
    footnoteTexts.push(footnoteText);
  }

  return (
    <MobileScreenWrapper>
      <Grid mb={2} display='flex' direction='row' sx={{ alignItems: 'center' }}>
        <Svgs.IconArrowLeft onClick={() => navigate('/')} />
        <Typography ml={1} color='primary' variant='p22Bold'>
          {showSelectPlan ? t('SelectPlan') : t('ManageSubscription')}
        </Typography>
      </Grid>
      {showSelectPlan && threeNickelsProducts ? (
        threeNickelsProducts.map((plan, i) => {
          const { footnoteCounter: counter, footnoteText } = getFootnoteElement(
            footnoteCounter,
            plan,
            inAdviceFreeTrial,
            subscription
          );
          if (footnoteText) {
            footnoteCounter = counter;
            footnoteTexts.push(footnoteText);
          }
          return (
            <Grid
              mt={i === 0 ? 0 : 2}
              key={plan.pricebook.id}
              display='flex'
              direction='column'
              sx={{ justifyContent: 'center' }}>
              <PlanCard
                plan={plan}
                currentPlan={currentPlan}
                pricebookMetadata={pricebookMetadata}
                subscriptionInfo={subscription}
                threeNickelsProducts={threeNickelsProducts}
                orgDoesNotOfferFreePricebook={orgDoesNotOfferFreePricebook}
                inAdviceFreeTrial={inAdviceFreeTrial}
                footnoteIndex={footnoteText ? footnoteCounter : undefined}
                mobileCardStyle={{
                  boxShadow: '0 4px 7px 1px rgba(30, 41, 105, 0.2)',
                }}
              />
            </Grid>
          );
        })
      ) : (
        <PlanCard
          plan={recentPlan}
          pricebookMetadata={pricebookMetadata}
          subscriptionInfo={subscription}
          threeNickelsProducts={threeNickelsProducts}
          freeTrialStatus={freeTrialStatus}
          inAdviceFreeTrial={inAdviceFreeTrial}
          userNeverSubscribedToExclusivePricebook={userNeverSubscribedToExclusivePricebook}
          footnoteIndex={footnoteText ? footnoteCounter : undefined}
          manageSubscription={!showSelectPlan}
        />
      )}
      {footnoteTexts.map((text, index) => (
        <Grid item mt={3} key={index}>
          <Footnote index={index} text={text} termsApi={termsApi} legalDocs={legalDocs} />
        </Grid>
      ))}
      {!showSelectPlan && (
        <PlanActionGroupButtons
          plan={recentPlan}
          subscriptions={subscriptionInfoData}
          threeNickelsProducts={threeNickelsProducts}
          inAdviceFreeTrial={inAdviceFreeTrial}
          orgPaysPricebook={orgPaysPricebook}
          paymentApi={paymentApi}
          multipleProducts={multipleProducts}
          orgOnlyOffersFreePricebook={orgOnlyOffersFreePricebook}
        />
      )}
      {planForTable.length > 0 && <PlanFeaturesTable products={planForTable} />}
    </MobileScreenWrapper>
  );
};
