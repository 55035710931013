/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { AccountSettingsWizard, accountSettingsWizardKey } from '../../../services/accountSettings';
import { useObservable } from '@aesop-fables/scrinium';
import { useMessage } from '../../../hooks/useMessage';
import { ConfirmationCodeField } from '../../../components';
import FormContent from '../../../components/form/FormContent';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const VerifyAuthMethodView: React.FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const commands = Hooks.useCommands();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const addTwoFactorMethodData = useObservable(accountSettingsWizard.addTwoFactorMethodData$);
  const methods = useForm();
  const authenticationMethod =
    addTwoFactorMethodData?.authenticationMethod ?? Api.TwoFactorTypeEnum.Email;
  const email = authenticationMethod === Api.TwoFactorTypeEnum.Email;
  const header = email ? 'VerifyYourEmail' : 'VerifyYourMobilePhoneNumber';

  const CELL_COUNT = 6;
  const [codeValue, setCodeValue] = useState<string>('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState<boolean>(true);

  const handleChange = (x: string) => {
    setCodeValue(x);
  };

  const onResendCode = async () => {
    try {
      setCodeValue('');
      await commands.execute(Data.TwoFactor.Commands.AddMethodResendCode, authenticationMethod);
      showMessage('Authentication Code Sent');
    } catch (err) {
      showMessage('We weren’t banking on that happening...please try again later.', 'info');
    }
  };

  const verifyTwoFactorMethod = async () => {
    try {
      await commands.execute(Data.TwoFactor.Commands.VerifyTwoFactorMethod, {
        code: codeValue,
        type: authenticationMethod,
      });
      accountSettingsWizard.resetAddTwoFactorMethodData();
      showMessage('Authentication method added!');
      navigate('/settings/two-factor-authentication');
    } catch (err) {
      const error = err as AxiosError;
      const errorMessage = (error.response?.data as Record<string, any>)?.message;
      if (errorMessage === 'Email already in use.') {
        showMessage(
          'An account using this email already exists. Please choose a unique email.',
          'info'
        );
      } else if (errorMessage === 'Phone number already in use.') {
        showMessage(
          'An account using this phone number already exists. Please choose a unique phone number.',
          'info'
        );
      } else {
        showMessage('We weren’t banking on that happening...please try again later.', 'info');
      }
    }
  };

  const onSubmit = methods.handleSubmit(() => {
    verifyTwoFactorMethod();
  });

  useEffect(() => {
    if (String(codeValue).length === CELL_COUNT) setDisabledSubmitButton(false);
    else setDisabledSubmitButton(true);
  }, [codeValue]);

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography variant='p30Bold' component='h1' color='primary'>
          {t(`${header}`)}
        </Typography>
        <Typography mt={2} variant='p16' color='secondary'>
          {t('EnterThe6DigitCodeWeSentToYour')}&nbsp;
          {email ? 'email address' : 'mobile phone number'}.
        </Typography>
        {email && (
          <Typography mt={2} variant='p16' color='error.main'>
            {t('CheckYourJunkFolder')}
          </Typography>
        )}
        <FormContent formProviderProps={methods}>
          <Box component='form' onSubmit={onSubmit}>
            <Grid container mt={6} flexDirection='column' alignItems='center'>
              <ConfirmationCodeField fieldName='2fa' value={codeValue} onChange={handleChange} />
              <Button
                onClick={onResendCode}
                className='ghostAlt'
                sx={{ maxWidth: 'fit-content', marginTop: '20px' }}>
                {t('ResendCode')}
              </Button>
            </Grid>
            <WizardFooter onDone={onSubmit} nextLabel='Submit' disableNext={disabledSubmitButton} />
          </Box>
        </FormContent>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: false,
  hexHeader: true,
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(VerifyAuthMethodView, meta);
