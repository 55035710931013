import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';
import { useLoading } from './useLoading';
import { usePlaidLink } from 'react-plaid-link';
import { ICommandExecutor } from '@aesop-fables/scrinium';

async function reloadCaches(
  accessId: number,
  linkedAccounts: Domain.GenericAutoBindAccount[],
  commands: ICommandExecutor
) {
  const targetAccounts = linkedAccounts.filter((account) => account.accessId === accessId);
  const financialAccountTypes = Array.from(
    new Set(targetAccounts.map((account) => account.financialAccountTypes))
  );

  financialAccountTypes.forEach(async (type) => {
    switch (type) {
      case Domain.FinancialAccountTypeEnum.CashAccount:
        await commands.relay(Data.CashAccounts.Commands.ReloadCashAccountCache);
        break;
      case Domain.FinancialAccountTypeEnum.CollegeSavingsAccount:
        await commands.relay(Data.CollegeSavingsPlans.Commands.ReloadCollegeSavingsPlanCache);
        break;
      case Domain.FinancialAccountTypeEnum.CreditCard:
        await commands.relay(Data.CreditCards.Commands.ReloadCreditCardCache);
        break;
      case Domain.FinancialAccountTypeEnum.Debt:
        await commands.relay(Data.Debt.Commands.ReloadDebtCompartment);
        await commands.relay(Data.RealEstate.Commands.ReloadHousingEntityCache);
        break;
      case Domain.FinancialAccountTypeEnum.Institutional:
      case Domain.FinancialAccountTypeEnum.InvestmentAccount:
        await commands.relay(Data.InvestmentAccounts.Commands.ReloadInvestmentAccountCache);
        break;
      default:
        break;
    }
  });
}

export function usePlaidCredentialRefresh(id?: number) {
  const [token, setToken] = useState<string | null>(null);
  const [accessId, setAccessId] = useState<number | undefined>(id);
  const commands = Hooks.useCommands();
  const wizard = Hooks.usePlaidWizard();
  const linkedAccounts = Hooks.useLinkedAccounts();
  const { setLoading } = useLoading();

  const { open } = usePlaidLink({
    token,
    async onSuccess() {
      setLoading(true);
      try {
        await commands.execute(Data.Plaid.Commands.RefreshPlaidInstitution, {
          accessId,
        });
        await reloadCaches(accessId ?? 0, linkedAccounts, commands);
      } finally {
        setLoading(false);
      }

      setToken(null);
    },
    onExit() {
      setToken(null);
    },
  });

  const updateLoginHandler = async (value?: number) => {
    if (id && !accessId) setAccessId(id);
    const newToken = await wizard.getExpiredCredentialsToken(value ?? id ?? 0);
    setToken(newToken);
  };

  const setIdAndUpdateLogin = (value: number) => {
    setAccessId(value);
    updateLoginHandler(value);
  };

  useEffect(() => {
    if (token && accessId) {
      open();
    }
  }, [token, accessId, open]);

  return { updateLoginHandler, setIdAndUpdateLogin };
}
