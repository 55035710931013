import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Typography, Button, Grid, GridProps, ButtonProps } from '@mui/material';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { SeeLegalDocs } from '../../settings/manage-subscription/Footnote';
import { Colors } from '../../../themes';
import { useCallback, useMemo } from 'react';

const DEFAULT_LINK_COLOR = Colors.primaryBase;

type SubscriptionDetailsProps = GridProps & {
  linkColor?: string;
  buttonProps?: ButtonProps;
};

export const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  linkColor = DEFAULT_LINK_COLOR,
  buttonProps,
  ...gridProps
}) => {
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const legalDocs = Hooks.useTermsAndConditions();
  const { sortedPaidProducts } = Hooks.useAvailableProducts();

  const selectedPlan = useMemo(() => sortedPaidProducts[0], [sortedPaidProducts]);
  const intervalType = selectedPlan?.prices[0].intervalType;
  const oneTimePayment = intervalType === Domain.PaymentIntervalEnum.NONE;

  const primaryPrice = useMemo(
    () => selectedPlan?.prices[0].price && selectedPlan.prices[0].price / 100,
    [selectedPlan]
  );
  const secondaryPrice = useMemo(
    () => selectedPlan?.prices[1]?.price && selectedPlan.prices[1]?.price / 100,
    [selectedPlan]
  );
  const promotionalPeriodInMonths = useMemo(
    () =>
      selectedPlan?.prices[1]?.introductionPeriodInDays &&
      Math.round(selectedPlan.prices[1]?.introductionPeriodInDays / 30),
    [selectedPlan]
  );

  const buttonText = oneTimePayment
    ? `Purchase for $${primaryPrice}`
    : `Subscribe for $${primaryPrice}/${intervalType}`;

  const description = oneTimePayment
    ? `Initially a one-time payment. After the ${promotionalPeriodInMonths}-month promotional period, Advice subscription auto-renews at $${secondaryPrice}/month and will continue to auto-renew until you cancel.`
    : 'This subscription automatically renews every month and will continue to auto-renew until you cancel.';

  const handleButtonClick = useCallback(async () => {
    if (selectedPlan) {
      const stripeUrl = await paymentApi.createSession(selectedPlan.pricebook.id);
      window.location.replace(stripeUrl.data);
    }
  }, [selectedPlan, paymentApi]);

  return (
    <Grid {...gridProps} flexDirection='column' textAlign='center' justifyContent='center'>
      <Grid item mb={2}>
        <Typography className='link-wrapper' variant='p12' color='secondary'>
          {description}{' '}
          <SeeLegalDocs
            termsApi={termsApi}
            legalDocs={legalDocs}
            linkStyle={{
              color: linkColor,
              textDecorationColor: linkColor,
              fontWeight: linkColor === DEFAULT_LINK_COLOR ? 'normal' : 'bold',
            }}
          />
        </Typography>
      </Grid>
      <Button {...buttonProps} onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </Grid>
  );
};
