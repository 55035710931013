import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Column, Row, Spacer, ConfirmationCodeField } from '../components';
import { logo3NSmall } from '../assets/png';
import { Form } from 'react-final-form';
import { useEffect, useState } from 'react';
import {
  AuthWizardStateDefault,
  SignUpLoginWizard,
  signUpLoginWizardKey,
} from '../services/signUpLogin';
import { useObservable } from '@aesop-fables/scrinium';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { useLoading } from '../hooks/useLoading';
import { useService } from '@aesop-fables/containr-react';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { Api } from '@3nickels/data-modules';
import { useMessage } from '../hooks/useMessage';

const LoginTwoFactorAuth = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const [codeValue, setCodeValue] = useState<string>('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState<boolean>(true);

  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);
  const state = useObservable(signUpLoginWizard.state$) ?? AuthWizardStateDefault;
  const authApi = useService<Api.AuthApi>(Api.ApiKeys.AuthApi);

  const userInfo = state.loginData;
  const authMethodInfo = state.authMethod ?? state.loginResultData.defaultTwoFactor?.info;
  const isEmail =
    state.loginResultData.defaultTwoFactor?.twoFactorType?.toLowerCase() ===
    Api.TwoFactorTypeEnum.Email;
  const { isMobile } = useDeviceType();

  const navBack = () => {
    navigate(-1);
  };

  const CELL_COUNT = 6;
  useEffect(() => {
    if (String(codeValue).length === CELL_COUNT) setDisabledSubmitButton(false);
    else setDisabledSubmitButton(true);
  }, [codeValue]);

  async function onSubmit() {
    try {
      setLoading(true);
      const redirectLocation = await authApi.validateTwoFactor({
        code: codeValue,
        twoFactorType: Api.TwoFactorTypeEnum.Email,
      });

      // await accountDataCache.reloadAll();
      // send user to backend redirect for now
      // if (signUpInProgress) {
      //   navigate('/review');
      //   setLoading(false);
      // } else {
      // don't need to set loading to false because this triggers a refresh of the app
      // window.location = redirectLocation.data;
      // }
      setLoading(false);
      window.location = redirectLocation.data;
    } catch (err) {
      console.error(err);
      setLoading(false);
      showMessage('We weren’t banking on that happening...please try again later.', 'error');
    }
  }

  const onResendCode = async () => {
    try {
      setCodeValue('');
      await signUpLoginWizard.saveLoginDataAndAttemptLogin(
        userInfo.username ?? '',
        userInfo.password ?? ''
      );
      showMessage('Authentication Code Sent', 'success', 4000);
    } catch (err) {
      console.error(err);
      showMessage('We weren’t banking on that happening...please try again later.', 'error');
    }
  };

  const handleChange = (x: string) => {
    setCodeValue(x);
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile ? mobileMargin : '15px 100px' }}>
              <img src={logo3NSmall} alt='logo' style={{ height: '55px', alignSelf: 'flex-end' }} />
              <Typography variant='p30Bold' color='primary'>
                Two-Factor Authentication
              </Typography>
              <Spacer height='xs' />
              <Typography variant='h2' color='secondary'>
                Enter the 6-digit code we sent to {authMethodInfo}.
              </Typography>
              <Spacer height='xs' />
              {isEmail && (
                <Typography variant='p16' color='error.main'>
                  If you don’t see the email, please check your junk folder, and move it to your
                  inbox so it’s no longer marked as junk.
                </Typography>
              )}
              <Spacer height='lg' />
              <Column style={{ alignItems: 'center' }}>
                <ConfirmationCodeField fieldName='2fa' value={codeValue} onChange={handleChange} />
                <Spacer height='lg' />
                <Button onClick={onResendCode} className='ghostAlt'>
                  Resend Code
                </Button>
              </Column>
              <Spacer height='sm' />
              {isMobile ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleSubmit} disabled={disabledSubmitButton} color='secondary'>
                    Submit
                  </Button>
                  <Spacer height='xs' />

                  <Button onClick={navBack} variant='outlined' color='secondary'>
                    Back
                  </Button>
                </Column>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
                    Back
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    disabled={disabledSubmitButton}
                    className='md'
                    type='submit'
                    color='secondary'
                    style={{ alignSelf: 'flex-end' }}>
                    Submit
                  </Button>
                </Row>
              )}
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default LoginTwoFactorAuth;
