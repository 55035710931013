import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@3nickels/data-modules';
import { Button, Card, Grid, Link, Typography } from '@mui/material';
import { ChargeData } from '../../../api/apis/PaymentApi';
import {
  convertToTitleCase,
  formatDateMMDDYYYY,
  formatDecimalDollars,
} from '../../../helpers/utilityFunctions';
import { Colors } from '../../../themes';
import { Column } from '../../../components';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { useDeviceType } from '../../../hooks/useDeviceType';

interface PaymentHistorySectionProps {
  stripeTransactionsData: ChargeData[] | undefined;
  subscriptionInfoData: Domain.SubscriptionInfo[] | undefined;
}

const PaymentHistorySection: React.FC<PaymentHistorySectionProps> = ({
  stripeTransactionsData,
  subscriptionInfoData,
}) => {
  const { t } = useTranslation();
  const [numShown, setNumShown] = useState<number>(5);
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  if (!stripeTransactionsData || stripeTransactionsData.length === 0) {
    const appleOrAndroid = subscriptionInfoData?.filter(
      (subscription) => subscription.origin !== Domain.PaymentOriginEnum.STRIPE
    );

    if (!appleOrAndroid || appleOrAndroid.length === 0) {
      return (
        <Card
          className='card-row'
          sx={{ padding: mobile ? '20px' : '10px', marginTop: mobile ? undefined : '20px' }}>
          <Grid container display='flex' alignItems='center' justifyContent='center'>
            <Typography variant='p16' color='secondary'>
              {t('YouDontHaveAnyPaymentHistory')}
            </Typography>
          </Grid>
        </Card>
      );
    } else {
      const appleOrigin = appleOrAndroid[0].origin === Domain.PaymentOriginEnum.APPLE;
      const storeHref = appleOrigin
        ? 'https://www.apple.com/app-store/'
        : 'https://play.google.com/store/paymentmethods';

      return (
        <Card
          className='card-row'
          sx={{ padding: mobile ? '20px' : '10px', marginTop: mobile ? undefined : '20px' }}>
          <Grid container display='flex' alignItems='center'>
            <Grid item xs={2.5} />
            <Grid
              item
              xs={7}
              style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Typography variant='p16' color='secondary'>
                Please check {appleOrigin ? 'Apple' : 'Google'} for any payment history.
              </Typography>
            </Grid>
            <Grid item xs={0.5} />
            {!mobile && (
              <Grid item xs={2} paddingRight={1} style={{ textAlign: 'end' }}>
                <Link variant='p12' noWrap href={storeHref} target='_blank'>
                  Go to&nbsp;
                  {appleOrigin ? 'App Store' : 'Google Play Store'}
                </Link>
              </Grid>
            )}
          </Grid>
          {mobile && (
            <Grid
              mt={2.5}
              display='flex'
              direction='row'
              sx={{
                justifyContent: 'center',
              }}>
              <Link
                sx={{ textDecoration: 'none' }}
                variant='p14SemiBold'
                noWrap
                href={storeHref}
                target='_blank'>
                Go to&nbsp;
                {appleOrigin ? 'App Store' : 'Google Play Store'}
              </Link>
            </Grid>
          )}
        </Card>
      );
    }
  }

  return (
    <>
      {!mobile && (
        <Grid
          container
          mt={3}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          padding='0px 5px'>
          <Grid item xs={0.5} paddingLeft={1} />
          <Grid item xs={4}>
            <Typography variant='p14' color='primary'>
              {t('Description')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='p14' color='primary'>
              {t('TotalAmount')}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant='p14' color='primary'>
              {t('PaymentMethod')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='p14' color='primary'>
              {t('DatePaid')}
            </Typography>
          </Grid>
          <Grid item xs={1} paddingRight={1} />
        </Grid>
      )}
      {stripeTransactionsData.slice(0, numShown).map((stripeTransaction) => {
        return (
          <HistoryCard key={stripeTransaction.charge.id} stripeTransaction={stripeTransaction} />
        );
      })}
      {stripeTransactionsData.length > 5 && numShown < stripeTransactionsData.length && (
        <Grid container display='flex' alignItems='center' justifyContent='center'>
          <Button
            onClick={() => setNumShown(numShown + 5)}
            className='ghostAlt'
            sx={{ maxWidth: 'fit-content' }}
            disableRipple>
            Load more
          </Button>
        </Grid>
      )}
    </>
  );
};

interface HistoryCardProps {
  stripeTransaction: ChargeData;
}

const HistoryCard: React.FC<HistoryCardProps> = ({ stripeTransaction }) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  const openDetails = () => {
    navigate('details', { state: { payment: stripeTransaction } });
  };

  return (
    <>
      {mobile ? (
        <Grid
          mb={1.5}
          display='flex'
          direction='row'
          justifyContent='space-between'
          onClick={openDetails}
          sx={{
            borderRadius: '7px',
            padding: '20px',
            border: `solid 1px ${Colors.primaryLightest}`,
            backgroundColor: Colors.tertiaryBase,
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          <Column>
            <Typography mb={1} variant='p16Bold' color='secondary'>
              {stripeTransaction.charge.description}
            </Typography>
            <Typography mb={1} variant='p16' color='secondary'>
              {formatDecimalDollars(stripeTransaction.charge.amountPaid / 100)}
            </Typography>
            <Typography mb={1} variant='p16' color='secondary'>
              {convertToTitleCase(stripeTransaction.paymentInfo.paymentMethod)} ending in&nbsp;
              {stripeTransaction.paymentInfo.last4}
            </Typography>
            <Typography variant='p16' color='secondary'>
              {formatDateMMDDYYYY(stripeTransaction.charge.datePaid)}
            </Typography>
          </Column>
          <Column>
            <Svgs.IconArrowRight />
          </Column>
        </Grid>
      ) : (
        <Card
          className='card-row'
          sx={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
          <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={0.5} paddingLeft={1} />
            <Grid item xs={4}>
              <Typography variant='p16Bold' color='secondary'>
                {stripeTransaction.charge.description}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='p16Bold' color='secondary'>
                {formatDecimalDollars(stripeTransaction.charge.amountPaid / 100)}
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography variant='p16' color='secondary'>
                {convertToTitleCase(stripeTransaction.paymentInfo.paymentMethod)} ending in&nbsp;
                {stripeTransaction.paymentInfo.last4}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='p16' color='secondary'>
                {formatDateMMDDYYYY(stripeTransaction.charge.datePaid)}
              </Typography>
            </Grid>
            <Grid item xs={1} paddingRight={1} />
          </Grid>
        </Card>
      )}
    </>
  );
};

export default PaymentHistorySection;
