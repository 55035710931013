import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

declare type CollapsibleCardProps = PropsWithChildren & {
  header?: React.ReactNode | string;
  headerXs?: number;
  summary?: React.ReactNode | string;
  footnote?: string;
  icon?: React.ReactNode;
  variant?: 'compressed' | 'shadow' | 'contained';
  expandIconPosition?: 'relative' | 'end';
  collapsedHeaderStyle?: React.CSSProperties;
  initiallyExpanded?: boolean;
};

declare type CompressedPanelProps = CollapsibleCardProps & {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const CompressedPanel: React.FC<CompressedPanelProps> = (props) => {
  const { expanded, setExpanded } = props;
  const showFootnote = useMemo(() => typeof props.footnote !== 'undefined', [props.footnote]);

  return (
    <Card className='collapsible-panel'>
      <CardHeader
        className='compressed-panel'
        style={expanded ? undefined : props.collapsedHeaderStyle}
        onClick={() => {
          if (props.children) {
            setExpanded(!expanded);
          }
        }}
        color='secondary'
        action={
          <Grid>
            <Typography
              className='collapsible-panel-summary'
              component='h1'
              variant='p18SemiBold'
              color='secondary'
              sx={{ display: 'block', marginRight: '5px' }}>
              {props.summary}
            </Typography>
            {props.expandIconPosition === 'end' && (
              <Grid className='icon' item sx={{ marginLeft: '10px' }}>
                <IconButton>
                  {expanded ? (
                    <KeyboardArrowUpIcon fontSize='large' color='primary' />
                  ) : (
                    <KeyboardArrowDownIcon fontSize='large' color='primary' />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        }
        disableTypography={true}
        title={
          <Grid container className='collapsible-panel-title'>
            <Grid item justifySelf='center' alignSelf='center'>
              <Typography component='h2' variant='p18Bold' color='primary'>
                {props.header}
              </Typography>
            </Grid>
            {showFootnote && (
              <Grid item justifySelf='center' alignSelf='center' sx={{ marginLeft: '10px' }}>
                <Typography component='h3' color='primary'>
                  {props.footnote}
                </Typography>
              </Grid>
            )}
            {props.expandIconPosition === 'relative' && (
              <Grid className='icon' item sx={{ marginLeft: '10px' }}>
                <IconButton>
                  {expanded ? (
                    <KeyboardArrowUpIcon fontSize='large' color='primary' />
                  ) : (
                    <KeyboardArrowDownIcon fontSize='large' color='primary' />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        }
        avatar={props.icon}
      />
      <Collapse in={expanded}>
        <CardContent>{props.children}</CardContent>
      </Collapse>
    </Card>
  );
};

export const CollapsiblePanel: React.FC<CollapsibleCardProps> = (props) => {
  const [expanded, setExpanded] = React.useState(props.initiallyExpanded ?? false);
  const showFootnote = useMemo(() => typeof props.footnote !== 'undefined', [props.footnote]);
  const { expandIconPosition = 'relative' } = props;

  const [titleMaxWidth, setTitleMaxWidth] = useState<string>();
  const cardHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateTitleWidth = () => {
      if (cardHeaderRef.current) {
        const adjustedWidth = cardHeaderRef.current.offsetWidth * (props.icon ? 0.8 : 1);
        setTitleMaxWidth(`${adjustedWidth}px`);
      }
    };
    updateTitleWidth();
    window.addEventListener('resize', updateTitleWidth);
    return () => window.removeEventListener('resize', updateTitleWidth);
  }, [titleMaxWidth]);

  if (props.variant === 'compressed') {
    return <CompressedPanel {...props} expanded={expanded} setExpanded={setExpanded} />;
  }

  return (
    <Card className='collapsible-panel' variant={props.variant}>
      <CardHeader
        ref={cardHeaderRef}
        className='collapsible-panel-header'
        style={expanded ? undefined : props.collapsedHeaderStyle}
        onClick={() => {
          if (props.children) {
            setExpanded(!expanded);
          }
        }}
        color='secondary'
        role={expanded ? 'expanded' : undefined}
        action={
          <Grid>
            <Typography
              className='collapsible-panel-summary'
              component='h1'
              fontFamily='Montserrat'
              variant='p20Bold'
              color='primary'
              sx={{ display: 'block', marginRight: '5px' }}>
              {props.summary}
            </Typography>
            {expandIconPosition === 'end' && (
              <Grid className='icon' item sx={{ marginLeft: '10px' }}>
                {expanded ? (
                  <KeyboardArrowUpIcon fontSize='large' color='primary' />
                ) : (
                  <KeyboardArrowDownIcon fontSize='large' color='primary' />
                )}
              </Grid>
            )}
          </Grid>
        }
        disableTypography={true}
        title={
          <Grid container className='collapsible-panel-title'>
            <Grid
              item
              xs={props.headerXs ? props.headerXs : undefined}
              justifySelf='center'
              alignSelf='center'
              maxWidth={titleMaxWidth}>
              <Typography component='h2' variant='p18Bold' color='secondary'>
                {props.header}
              </Typography>
            </Grid>
            {showFootnote && (
              <Grid item justifySelf='center' alignSelf='center' sx={{ marginLeft: '10px' }}>
                <Typography component='h3' color='primary'>
                  {props.footnote}
                </Typography>
              </Grid>
            )}
            {expandIconPosition === 'relative' && (
              <Grid className='icon' item sx={{ marginLeft: '10px' }}>
                {expanded ? (
                  <KeyboardArrowUpIcon fontSize='large' color='primary' />
                ) : (
                  <KeyboardArrowDownIcon fontSize='large' color='primary' />
                )}
              </Grid>
            )}
          </Grid>
        }
        avatar={props.icon}
      />
      <Collapse in={expanded}>
        <CardContent>{props.children}</CardContent>
      </Collapse>
    </Card>
  );
};
