import { Button, Card, Link, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, Link as DomLink } from 'react-router-dom';
import { Observable } from 'rxjs';
import Cookies from 'js-cookie';
import { Column, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import { useDeviceType } from '../hooks/useDeviceType';
import { PaymentApi, PaymentStatusData } from '../api/apis/PaymentApi';
import { PaymentCompartments, paymentStorageKey } from '../data/payment';
import { mobileMargin } from '../App';
import { ProductOptions } from './Welcome';
import { LicenseWizard, licenseWizardKey } from '../services/license';
import { ApiKeys } from '../api/apis/ApiKeys';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { API_URL } from '../configuration/Environment';

const Review = () => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const { isMobile, isTablet } = useDeviceType();

  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);

  const licenseWizard = useService<LicenseWizard>(licenseWizardKey);
  const licenseState = useObservable(licenseWizard.state$);

  const organizationData = Hooks.useOrganizationData();
  const organizationMetadata = Hooks.useOrganizationMetadata();

  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const paymentStatus = useObservable(
    paymentDataCache.observe$('paymentStatus') as Observable<PaymentStatusData>
  );

  const [memberPaidOptions, setMemberPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [tosLink, setTosLink] = useState<string>();

  const promotion = licenseState?.pricebook?.prices.find(
    (price) => price.introductionPeriodInDays > 1
  );
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);

  const pricebook = licenseState?.pricebook;
  const checkOut = !paymentStatus?.hasActiveLicense;

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  useEffect(() => {
    if (!pricebook) return;
    const member = pricebook.pricebook.paidBy === 'USER' ? [pricebook] : [];
    const org = pricebook.pricebook.paidBy === 'ORG' ? [pricebook] : [];
    setMemberPaidOptions(member);
    setOrgPaidOptions(org);
  }, [pricebook]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getDocs();
      const termsObject = res.data.find((doc) => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.getDocString(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
  }, [termsApi]);

  const onSubmit = async () => {
    if (!pricebook) {
      return;
    }

    try {
      if (checkOut === false) {
        navigate('/congrats');
      } else {
        const stripeUrl = await paymentApi.createSession(pricebook.pricebook.id);
        window.location.replace(stripeUrl.data);
      }
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + pricebook.pricebook.id);
    }
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
              <Row
                style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
                {organizationData ? (
                  organizationData.logoUri ? (
                    <img
                      src={`${API_URL}organization/logo`}
                      alt={organizationData?.name}
                      style={{ height: '60%' }}
                    />
                  ) : (
                    <Typography variant='p25Bold' color='secondary'>
                      {organizationData?.name}
                    </Typography>
                  )
                ) : (
                  <Card variant='ghost' />
                )}
                <DomLink style={{ height: '70%' }} to='/'>
                  <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
                </DomLink>
              </Row>
              <Typography variant='p30Bold' color='primary'>
                Review and {checkOut ? `Check Out` : `Confirm`}
              </Typography>
              <Spacer height='xs' />
              <Typography variant='h2' color='secondary'>
                You’re almost there!{' '}
                {checkOut
                  ? `Review your package before checking out.`
                  : `Just take a second to review your subscription.`}
              </Typography>
              <Spacer height='xs' />
              <ProductOptions
                memberPaidOptions={memberPaidOptions}
                orgPaidOptions={orgPaidOptions}
                pricebookMetadata={pricebookMetadata}
              />
              {!isMobile && <Spacer height='xxxs' />}
              {checkOut ? (
                <>
                  <Typography className='link-wrapper' variant='p14' color='secondary'>
                    Please see our{' '}
                    <Link href={tosLink} target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href='/cancellation-policy' target='_blank'>
                      Cancellation and Refund Policy
                    </Link>{' '}
                    for details
                  </Typography>
                  {promotion && (
                    <>
                      <Spacer height='xs' />
                      <Typography variant='p14' color='secondary'>
                        * After {introPeriodInMonths} months, Advice subscription auto-renews at
                        $14.99/month
                      </Typography>
                      {isMobile && <Spacer height='sm' />}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography className='link-wrapper' variant='p14' color='secondary'>
                    Please see our{' '}
                    <Link href={tosLink} target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    for details
                  </Typography>
                  {(isMobile || isTablet) && <Spacer height='sm' />}
                </>
              )}
              {isMobile ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  {checkOut ? (
                    <Button onClick={handleSubmit} color='secondary'>
                      Check Out
                    </Button>
                  ) : (
                    <Button onClick={handleSubmit} color='secondary'>
                      Confirm
                    </Button>
                  )}
                  <Spacer height='xs' />
                  <form method='post' action={API_URL.replace('/api', '/') + 'logout'}>
                    <Button
                      type='submit'
                      variant='outlined'
                      color='secondary'
                      style={{ width: '100%' }}>
                      Cancel &amp; Log Out
                    </Button>
                    <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                  </form>
                </Column>
              ) : (
                <>
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <form method='post' action={API_URL.replace('/api', '/') + 'logout'}>
                      <Button type='submit' variant='outlined' color='secondary'>
                        Cancel &amp; Log Out
                      </Button>
                      <input
                        type='hidden'
                        name='_csrf'
                        value={Cookies.get('XSRF-TOKEN')}
                        readOnly
                      />
                    </form>
                    {checkOut ? (
                      <Button
                        className='sm'
                        onClick={handleSubmit}
                        type='submit'
                        color='secondary'
                        style={{ width: 'fit-content' }}>
                        Check Out
                      </Button>
                    ) : (
                      <Button
                        className='sm'
                        onClick={handleSubmit}
                        color='secondary'
                        type='submit'
                        style={{ width: 'fit-content' }}>
                        Confirm
                      </Button>
                    )}
                  </Row>
                </>
              )}
              <Spacer height='sm' />
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default Review;
