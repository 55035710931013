import { Backdrop } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MainContent } from '../dashboard/style';
import { useDeviceType } from '../hooks/useDeviceType';
import { Loading, useLoading } from '../hooks/useLoading';
import { useService, useServiceContainer } from '@aesop-fables/containr-react';
import { Authentication, Data, Domain, Hooks } from '@3nickels/data-modules';
import { useAuthenticatedFlag, useAuthenticationStatus, useNeedsOnboarding } from '../hooks';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import SignUpRoutes from './SignUpRoutes';
import { createAdviceRoutes } from './AdviceRoutes';
import { ContentViewProvider } from '../pages/layout/ContentViewContext';
import { createAdviceOnboardingRoutes } from './AdviceOnboardingRoutes';
import { createFreeRoutes } from './FreeRoutes';
import { useMessage } from '../hooks/useMessage';
import { createMobileRoutes } from './MobilesRoutes';

function AppGateway() {
  const { loading } = useLoading();
  const authContext = useService<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const isAuthenticated = useAuthenticatedFlag();
  const needsOnboarding = useNeedsOnboarding();
  const productType = Hooks.useProductType();
  const freeTrialInfo = Hooks.useFreeTrialInfo();
  const principalUser = Hooks.usePrincipalUser();
  const { accountId } = principalUser ?? {};
  const { isReady, isSessionExpired } = useAuthenticationStatus();
  const { showMessage } = useMessage();
  const { isMobile, isTablet } = useDeviceType();
  const container = useServiceContainer();
  const logger = Hooks.useLogger();

  useEffect(() => {
    if (isReady && isSessionExpired) {
      authContext.setIsAuthenticated(false);
      showMessage('You have been logged out due to inactivity', 'error');
    }
  }, [isSessionExpired, isReady]);

  // Web crawlers don’t always execute JavaScript code when examining a webpage, so if you want to make sure that they read your meta tags, you need to set them up before the browser receives the page.
  const router = useMemo(() => {
    if (isAuthenticated) {
      if (isMobile || isTablet) {
        return createBrowserRouter(createMobileRoutes(container));
      }
      // wait for user data to load before determining free/advice routes
      if (accountId && !isMobile && !isTablet && typeof needsOnboarding !== 'undefined') {
        // advice
        if (
          productType === Domain.ProductTypeEnum.advice &&
          !freeTrialInfo?.showTrialExpiredScreen
        ) {
          if (needsOnboarding) {
            return createBrowserRouter(createAdviceOnboardingRoutes(container));
          }
          return createBrowserRouter(createAdviceRoutes(container));
        }
        // free
        return createBrowserRouter(createFreeRoutes(container));
      }
      return createBrowserRouter([
        {
          path: '*',
          element: <Loading />,
        },
      ]);
    }
    // unauthenticated
    return createBrowserRouter(createRoutesFromElements(SignUpRoutes()));
  }, [isAuthenticated, needsOnboarding, accountId, productType]);

  useEffect(() => {
    logger.debug(`AppGateway loading: ${loading}`);
  }, [loading]);

  return (
    <MainContent isMobile={isMobile || isTablet}>
      <Backdrop open={loading}>
        <Loading />
      </Backdrop>
      {isReady && (
        <ContentViewProvider>
          <Hooks.PlaidWizardProvider>
            <Data.Transactions.TransactionsSummaryProvider>
              <Data.Charity.CharityProvider>
                <RouterProvider router={router} />
              </Data.Charity.CharityProvider>
            </Data.Transactions.TransactionsSummaryProvider>
          </Hooks.PlaidWizardProvider>
        </ContentViewProvider>
      )}
    </MainContent>
  );
}

export default AppGateway;
