import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { useLoading } from './useLoading';
import { domain } from '../configuration/Environment';
import { useService } from '@aesop-fables/containr-react';
import { AuthTokenApi } from '../api/apis/AuthTokenApi';
import { PlatformSwitchApi } from '../api/apis/PlatformSwitchApi';
import { ApiKeys } from '../api/apis/ApiKeys';

export const useMobileRedirect = () => {
  const { setLoading } = useLoading();
  const authTokenApi = useService<AuthTokenApi>(ApiKeys.AuthToken);
  const platformSwitchApi = useService<PlatformSwitchApi>(ApiKeys.PlatformSwitch);

  return useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setLoading(true);

      try {
        await authTokenApi.generateCookie();
        const { data } = await platformSwitchApi.webToMobile();
        const url = new URL(data.redirectUrl);
        const appArgument = url.href;
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'apple-itunes-app');
        meta.setAttribute('content', `app-id=1544362343, appArgument=${appArgument}`);

        const form = document.createElement('form');
        const input = document.createElement('input');

        form.method = 'POST';
        form.action = `https://3n-auth.${domain}/api/platform-switch/web-to-mobile/redirect`;

        input.name = '_csrf';
        input.value = Cookies.get('XSRF-TOKEN') ?? '';

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
      } catch (error) {
        console.error('Error opening app:', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );
};
