import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Svgs } from '../assets/svg';
import { MenuLink, PopoverDropdown } from './menu/DropDownMenu';
import moment from 'moment';
import { usePlaidCredentialRefresh } from '../hooks/usePlaidCredentialRefresh';
import Modal from './Modal';
import { Spacing } from '../themes';
import Spacer from './Spacer';
import { useLoading } from '../hooks/useLoading';

export declare type SummaryHeaderProps = {
  accountName: string;
  balance?: number;
  balanceText?: string;
  accountType?: Domain.FinancialAccountTypeEnum;
  accountSubType?: string;
  linkableAccount?: Domain.ILinkableAccount;
  onLinkAccount?: () => void;
  onRemoveAccount?: () => void;
  getFinancialAccountId?: (account: Domain.ILinkableAccount) => number;
  organizationName?: string;
};

export const SummaryView: React.FC<SummaryHeaderProps & PropsWithChildren> = (props) => {
  return (
    <Box>
      <Grid display='none'></Grid>
      <SummaryHeader {...props} />
      {props.children}
    </Box>
  );
};

export const SummaryHeader: React.FC<SummaryHeaderProps> = ({
  accountName,
  accountType,
  accountSubType,
  balance,
  balanceText,
  linkableAccount,
  onLinkAccount,
  onRemoveAccount,
  getFinancialAccountId,
  organizationName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);
  const commands = Hooks.useCommands();
  const { updateLoginHandler } = usePlaidCredentialRefresh(
    linkableAccount?.linkedInstitution?.accessId
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const financialAccountId = useMemo(() => {
    if (!getFinancialAccountId) {
      return linkableAccount?.id ?? 0;
    }

    return getFinancialAccountId(linkableAccount as Domain.ILinkableAccount);
  }, [getFinancialAccountId, linkableAccount]);

  const showPlaid = useMemo(() => {
    return (
      linkableAccount?.isLinked === true &&
      typeof linkableAccount?.linkedInstitution !== 'undefined'
    );
  }, [linkableAccount]);

  const unlinkAccountHandler = async () => {
    setLoading(true);
    await commands.execute(Data.Plaid.Commands.UnlinkPlaidAccount, {
      financialAccountId,
      type: accountType ?? Domain.FinancialAccountTypeEnum.InvestmentAccount,
    });
    setLoading(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleRemoveAccount = () => {
    if (onRemoveAccount) {
      onRemoveAccount();
      handleClose();
    }
  };

  const menuLinks = useMemo(() => {
    const links: MenuLink[] = [];

    if (
      linkableAccount?.isLinked === true &&
      linkableAccount?.linkedInstitution?.requiresLogin === true
    ) {
      links.push({
        key: 'UpdateLogin',
        onClick: () => {
          updateLoginHandler();
          handleClose();
        },
      });
    }

    if (onLinkAccount && !linkableAccount?.isLinked) {
      links.push({
        key: 'LinkAccount',
        onClick: () => {
          onLinkAccount();
          navigate('/account-details/link-account/choose-institution', {
            state: { summaryUrl: location.pathname },
          });
          handleClose();
        },
      });
    }

    if (linkableAccount?.isLinked === true && accountType) {
      links.push({
        key: 'UnlinkAccount',
        onClick: () => {
          unlinkAccountHandler();
          handleClose();
        },
      });
    }

    if (onRemoveAccount) {
      links.push({
        key: 'RemoveAccount',
        onClick: () => {
          setModalOpen(true);
        },
      });
    }

    return links;
  }, [onLinkAccount]);

  return (
    <>
      <Grid container display='inline-flex' justifyContent='space-between'>
        <Grid container item width='70%'>
          <Grid container direction='row' display='flex' alignItems='center' width='90%'>
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
              <Typography
                variant='p30Bold'
                color='secondary'
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '10px',
                }}>
                {accountName}
              </Typography>
              <span
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleMenu}>
                <Svgs.ActionSmallEllipsis />
              </span>
            </div>
            <PopoverDropdown
              horizontalPosition={-50}
              onClose={handleClose}
              element={anchorEl}
              menuLinks={menuLinks}
            />
          </Grid>
          <Grid item>
            {(typeof organizationName !== 'undefined' || showPlaid) && (
              <Grid item>
                <Stack flexDirection='column' spacing={Spacing.xxxs} mt={1}>
                  <Typography variant='p16Bold' color='primary' component='span'>
                    {linkableAccount?.linkedInstitution?.institutionName}
                  </Typography>
                  <Stack flexDirection='row'>
                    {typeof organizationName !== 'undefined' && (
                      <Typography
                        color='primary'
                        component='span'
                        variant='p12'
                        mr={showPlaid ? 2 : 0}>
                        <Svgs.IconOrganization
                          height='20px'
                          width='20px'
                          style={{ marginRight: '3px' }}
                        />
                        {organizationName}
                      </Typography>
                    )}
                    {showPlaid && (
                      <Grid display='flex' alignItems='center'>
                        <Svgs.LinkPaperClip
                          height='20px'
                          width='20px'
                          style={{ marginRight: '3px' }}
                        />
                        {linkableAccount?.linkedInstitution?.requiresLogin ? (
                          <Typography color='accent' component='span' variant='p12'>
                            Login Expired:&nbsp;
                            <Typography
                              className='clickable'
                              component='span'
                              variant='p12'
                              onClick={() => updateLoginHandler()}>
                              Please Update
                            </Typography>
                          </Typography>
                        ) : (
                          <Typography color='primary' component='span' variant='p12'>
                            Linked Account
                            {!linkableAccount?.lastRefresh
                              ? ''
                              : `: Last Updated ${moment(linkableAccount.lastRefresh).fromNow()}`}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item width='30%' display='inline-flex' justifyContent='flex-end' marginTop='10px'>
          <Typography color='primary' marginRight='20px'>
            {balanceText
              ? balanceText
              : accountSubType === 'Other Asset' || accountSubType === 'Home'
              ? 'Total Value'
              : 'Current Balance'}
          </Typography>
          <Typography variant='p40Bold' color={(balance ?? 0) >= 0 ? 'secondary' : 'error'}>
            {formatWholeDollars(balance ?? 0)}
          </Typography>
        </Grid>
      </Grid>
      {modalOpen && (
        <Modal
          title='Remove Account?'
          primaryButtonText='No, Keep Account'
          secondaryButtonText='Yes, Remove Account'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={handleRemoveAccount}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this account. Are you sure you want to do
            that?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p16Bold' color='secondary'>
            {accountName}
          </Typography>
          <Typography variant='p16Bold' color='secondary'>
            {accountSubType}
          </Typography>
        </Modal>
      )}
    </>
  );
};
