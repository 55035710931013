import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { formatDateMMDDYYYY } from '../../../helpers/utilityFunctions';
import { useMessage } from '../../../hooks/useMessage';
import Modal, { BootstrapDialog, BootstrapHeader } from '../../../components/Modal';
import { Spacer } from '../../../components';
import { Button } from '../../../components/buttons/Button';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { getName } from './functions';

interface UnsubscribeModalProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subscription: Domain.SubscriptionInfo;
  threeNickelsProducts: Domain.PricebookBundle[] | undefined;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({
  modalOpen,
  setModalOpen,
  subscription,
  threeNickelsProducts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const commands = Hooks.useCommands();
  const { showMessage } = useMessage();
  const name = getName(subscription, threeNickelsProducts);
  const { expirationDate, origin, status } = subscription;
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  if (status === 'cancelled') {
    const handleClose = () => {
      setModalOpen(false);
    };

    return (
      <div style={{ borderRadius: '10px' }}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={modalOpen}>
          <BootstrapHeader id='customized-dialog-title' onClose={handleClose} />
          <Spacer height={'sm'} />
          <DialogContent className='institutional-modal-content'>
            <div style={{ textAlign: 'center' }}>
              <Typography mb={2} variant='p24Bold' color='secondary.main'>
                {t('YoureAlreadyUnsubscribedFrom') + ' ' + name}
              </Typography>
            </div>
            <Typography variant='p16' color='secondary'>
              Your subscription is cancelled.
            </Typography>
            <Typography mt={2} variant='p16' color='secondary'>
              You will lose access to {name} at the end of this subscription period on&nbsp;
              {formatDateMMDDYYYY(expirationDate)}.
            </Typography>
          </DialogContent>
          <DialogActions className='institutional-modal-actions'>
            <Button color='primary' size='large' onClick={handleClose}>
              <Typography variant='p16'>Dismiss</Typography>
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    );
  } else if (
    origin !== Domain.PaymentOriginEnum.STRIPE &&
    origin !== Domain.PaymentOriginEnum.FREE_TRIAL
  ) {
    const details = {
      ANDROID: {
        platform: 'Google',
        store: 'Google Play Store',
        url: 'https://play.google.com/store/account/subscriptions',
      },
      APPLE: {
        platform: 'Apple',
        store: 'App Store',
        url: 'https://apps.apple.com/account/subscriptions',
      },
    };

    const onGoToStore = () => {
      window.location.href = url;
    };

    const { platform, store, url } = details[origin];

    return (
      <Modal
        title={t('UnsubscribeFrom') + ' ' + name + '?'}
        primaryButtonText={'Go to ' + store}
        secondaryButtonText='Close'
        secondaryButtonColor={mobile ? 'primary' : undefined}
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={onGoToStore}>
        <Typography variant='p16' color='secondary'>
          Because your subscription is handled by {platform}, you must go to the {store} to cancel
          your subscription.
        </Typography>
      </Modal>
    );
  } else {
    const onCancelSubscription = async () => {
      try {
        await commands.execute(Data.SubscriptionInfo.Commands.CancelSubscription, {});
        showMessage('Unsubscribed successfully');

        navigate('/settings/manage-subscription');
      } catch (err) {
        showMessage('Failed to unsubscribe', 'info');
      }
    };

    return (
      <Modal
        title={t('UnsubscribeFrom') + ' ' + name + '?'}
        primaryButtonText='No, Keep Subscription'
        secondaryButtonText='Yes, Unsubscribe'
        secondaryButtonColor={mobile ? 'primary' : undefined}
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={onCancelSubscription}>
        <Typography variant='p16' color='secondary'>
          Are you sure you want to cancel your subscription?
        </Typography>
        <Typography mt={2} mb={1} variant='p16' color='secondary'>
          If you cancel, you will lose access to {name} at the end of this subscription period
          on&nbsp;
          {formatDateMMDDYYYY(expirationDate)}.
        </Typography>
      </Modal>
    );
  }
};

export default UnsubscribeModal;
