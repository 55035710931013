/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';
import { Domain } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { GoalsLayoutMeta } from './GoalsLayout';
import HexGrid, { HexGridItem } from '../../components/HexGrid';
import { Svgs } from '../../assets/svg';
import { Grid, Typography } from '@mui/material';
import { Spacing } from '../../themes';
import { Button } from '../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import { useGoalEditor } from './useGoalEditor';

declare type GoalType = Domain.GoalTypeEnum | 'Legacy';

declare type GoalSelectionItem = {
  type: GoalType;
  key: string;
};

const goalTypes: HexGridItem<GoalSelectionItem>[] = [
  {
    icon: <Svgs.DisplayMediumPrimaryCar />,
    label: 'Car',
    item: { key: 'car', type: Domain.GoalTypeEnum.Car },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryHouse />,
    label: 'House',
    item: { key: 'house', type: Domain.GoalTypeEnum.House },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCollege />,
    label: 'College',
    item: { key: 'college', type: Domain.GoalTypeEnum.College },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryPlane />,
    label: 'Vacation',
    item: { key: 'vacation', type: Domain.GoalTypeEnum.Vacation },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryUmbrella />,
    label: 'Emergency',
    item: { key: 'emergency', type: Domain.GoalTypeEnum['Emergency Fund'] },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryLegacy />,
    label: 'Legacy',
    item: { key: 'legacy', type: 'Legacy' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryRetirement />,
    label: 'Retirement',
    item: { key: 'retirement', type: Domain.GoalTypeEnum.Retirement },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryDebt />,
    label: 'Pay Off Debt',
    item: { key: 'debt', type: Domain.GoalTypeEnum.Non_Mortgage_Debt },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryPiggyBank />,
    label: 'Other',
    item: { key: 'other', type: Domain.GoalTypeEnum.Other },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryGifts />,
    label: 'Giving',
    item: { key: 'giving', type: Domain.GoalTypeEnum.Gift },
  },
];

export const SelectGoalType: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const editGoal = useGoalEditor();

  const goalTypeSelected = async (type: GoalType) => {
    if (type === 'Legacy') {
      navigate('/goals/legacy');
      return;
    }

    editGoal(type as Domain.GoalTypeEnum);
  };

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('AddAGoal')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('LetUsKnowAboutYourGoals')}
      </Typography>
      <HexGrid
        items={goalTypes}
        keyFn={(i) => i.key}
        onClick={(x) => goalTypeSelected(x.type)}
        itemsPerRow={5}
      />
      <Grid item sm={1} mt={Spacing.sm}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  // nextLocaleKey: 'Next',
  title: 'Select Goal Type',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(SelectGoalType, meta);
