import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import HomeBasicsCard from '../basics/HomeBasicsCard';
import HomeDetailsCard from '../details/HomeDetailsCard';
import HomeOtherCostsCard from '../other-costs/HomeOtherCostsCard';
import { HomeBasicsEditViewWrapper as HomeBasicsEditView } from '../basics/HomeBasicsEditView';
import { HomeDetailsEditViewWrapper as HomeDetailsEditView } from '../details/HomeDetailsEditView';
import { HomeOtherCostsEditViewWrapper as HomeOtherCostsEditView } from '../other-costs/HomeOtherCostsEditView';
import { SummaryView } from '../../../../components/SummaryHeader';
import { useMessage } from '../../../../hooks/useMessage';
import { useSummaryOnDoneNavigation } from '../../link-account/PlaidHooks';
import { useTranslation } from 'react-i18next';

declare type HomeSummaryDrawerType = keyof Data.RealEstate.HomeWizardRegistry;

const HomeSummaryView: React.FC = () => {
  const { t } = useTranslation();
  const data = Hooks.useHomeWizardData();
  const { wizard } = Hooks.useHomeWizard();
  const [isStarted, params] = useObservable(combineLatest([wizard.isStarted$, wizard.params$])) ?? [
    undefined,
    undefined,
  ];

  const { showMessage, hideMessage } = useMessage();
  const [needsAttention, setNeedsAttention] = React.useState<string | undefined>();
  const includeSpouse = Hooks.useIncludeSpouse();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const plaidWizard = Hooks.usePlaidWizard();
  const accounts = Hooks.useCombinedHousingData();
  const onDone = useSummaryOnDoneNavigation();
  const deleteMutation = useMutation(new Data.RealEstate.Mutations.DeleteHousingEntity());
  const account = accounts.find((x) => x.id === params?.id) as Domain.HousingData;

  const currentBalance = data.details.amountOwed
    ? (data.basic?.value ?? 0) - data.details.amountOwed
    : data.basic.value ?? 0;

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: Domain.RealEstateTypeEnum.PERSONAL });
    }
  }, [isStarted]);

  useEffect(() => {
    if (
      typeof data.details?.id !== 'undefined' &&
      (typeof data.details?.hasLoan === 'undefined' || data.details?.hasLoan === null)
    ) {
      showMessage(t('WereMissingSomeInfo') as string, 'info');
      setNeedsAttention('hasLoan');
    }
  }, [data.details?.hasLoan]);

  useEffect(() => {
    if (
      typeof data.details?.hasLoan !== 'undefined' &&
      data.details?.hasLoan !== null &&
      needsAttention === 'hasLoan'
    ) {
      hideMessage();
      setNeedsAttention(undefined);
    }
  }, [data.details?.hasLoan, needsAttention]);

  const openDrawer = (key?: HomeSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  const linkAccountHandler = useCallback(() => {
    const account = accounts.find((x) => x.id === params?.id) as Domain.HousingData;
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.Debt,
      'mortgage'
    );
  }, [plaidWizard, accounts]);

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('Home removed!');
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onLinkAccount={linkAccountHandler}
      getFinancialAccountId={() => account?.debt?.id ?? 0}
      linkableAccount={account}
      accountType={Domain.FinancialAccountTypeEnum.Debt}
      accountSubType={'Home'}
      onRemoveAccount={removeAccountHandler}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <HomeBasicsCard
            homeBasics={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <HomeDetailsCard homeDetails={data.details} onEdit={() => openDrawer('details')} />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <HomeOtherCostsCard
            homeOtherCosts={data.otherCosts}
            onEdit={() => openDrawer('otherCosts')}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(undefined as unknown as keyof Data.RealEstate.HomeWizardRegistry);
        }}
      />
      <WizardFooter nextLabel='Done' onDone={onDone} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useHomeWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;

  if (key === 'basic') {
    return <HomeBasicsEditView editing onBack={onBack} />;
  } else if (key === 'details') {
    return <HomeDetailsEditView editing onBack={onBack} />;
  } else if (key === 'otherCosts') {
    return <HomeOtherCostsEditView editing onBack={onBack} />;
  }

  return null;
};

export interface HomeSummaryCardProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(HomeSummaryView, meta);
