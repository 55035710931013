import { Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Colors } from '../../themes';
import { Row } from '../../components';

export const MobilePaymentsHistoryDetailsView: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { payment } = state;
  const receiptUrl = payment.charge.receiptUrl;

  const onViewReceipt = () => {
    window.open(receiptUrl);
  };

  return (
    <Grid container padding={'20px'} flexDirection='column' justifyContent='space-between' flex={2}>
      <Grid display='flex' direction='row' justifyContent='space-between'>
        <Typography mb={1} variant='p22Bold' color='primary'>
          View Past Invoice
        </Typography>
        <Svgs.IconNavAlertDefault onClick={() => navigate('/settings/manage-payments')} />
      </Grid>
      {!receiptUrl ? (
        <Typography mb={2.5} variant='p16' color='accent'>
          Your receipt hasn’t been generated yet. Check back in a few days to view and download it.
        </Typography>
      ) : (
        <Typography mb={2.5} variant='p16' color='secondary'>
          You can easily view and download your receipt.
        </Typography>
      )}
      {receiptUrl && (
        <Button color='primary' onClick={onViewReceipt}>
          View Receipt
        </Button>
      )}

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        Invoice Details
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Invoice Number
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.invoiceId}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Invoice Date
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.datePaid}
          </Typography>
        </Row>
      </Grid>

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        Product Details
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Product Name
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.description}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Total Price
          </Typography>
          <Typography variant='p16' color='secondary'>
            ${payment.charge.amountPaid / 100.0}
          </Typography>
        </Row>
      </Grid>

      <Typography mt={4} mb={1} variant='p18Bold' color='secondary'>
        Billing Details
      </Typography>
      <Grid
        padding={'10px'}
        sx={{
          borderRadius: '7px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.secondaryLighter,
        }}>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Name
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.name}
          </Typography>
        </Row>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Email
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.charge.email}
          </Typography>
        </Row>
        <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Zip Code
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.paymentInfo.zipCode}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography variant='p16Bold' color='primary'>
            Country
          </Typography>
          <Typography variant='p16' color='secondary'>
            {payment.paymentInfo.country}
          </Typography>
        </Row>
      </Grid>
    </Grid>
  );
};
