/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { Button, FormGroup, Typography } from '@mui/material';
import { useService } from '@aesop-fables/containr-react';
import { Column, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import {
  AccountSettingsWizard,
  accountSettingsWizardKey,
  ForgotPasswordData,
} from '../services/accountSettings';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { useObservable } from '@aesop-fables/scrinium';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { useMessage } from '../hooks/useMessage';
import TextInput from '../components/form/TextInput';
import { useForm } from 'react-hook-form';
import FormContent from '../components/form/FormContent';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { formatDateForApi } from '@3nickels/data-modules';
import { isValidDate } from '../helpers/utilityFunctions';

const schema = Yup.object({
  birthDate: Yup.string()
    .required('Required')
    .test('validDate', 'Must be a valid date', (birthDate) => {
      return isValidDate(birthDate);
    }),
  username: Yup.string().required('Required'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { showMessage } = useMessage();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const forgotPasswordData = useObservable(accountSettingsWizard.forgotPasswordData$);

  const methods = useForm<ForgotPasswordData>({
    defaultValues: forgotPasswordData,
    resolver: yupResolver(schema),
  });

  const formatInputs = (values: any) => {
    const birthDate =
      typeof values.birthDate !== 'undefined' && values.birthDate !== null
        ? formatDateForApi(values.birthDate)
        : values.birthDate;
    return { birthDate };
  };

  const onSubmit = async (values: ForgotPasswordData) => {
    try {
      const cleanedValues = formatInputs(values);
      await accountSettingsWizard.initiateResetPassword({ ...values, ...cleanedValues });
      accountSettingsWizard.setForgotPasswordData({ ...values, ...cleanedValues });
      navigate('/enter-code');
    } catch (err) {
      showMessage('We weren’t banking on that happening...please try again later.', 'error');
    }
  };

  const navBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (forgotPasswordData?.username) {
      methods.setValue('username', forgotPasswordData.username);
    }
  }, [forgotPasswordData?.username]);

  return (
    <PageContentWithBackground useContainer={false}>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
            <img src={logo3NSmall} alt='logo' style={{ height: '55px', alignSelf: 'flex-end' }} />
            <Spacer height='xs' />
            <Column style={{ width: isMobile || isTablet ? '100%' : '60%' }}>
              <Typography variant={isMobile || isTablet ? 'p22Bold' : 'p50Bold'} color='primary'>
                Forgot Password?
              </Typography>
              <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
              <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                Don’t worry, it happens to all of us.
              </Typography>
              <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
              <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                Just enter your date of birth and the email address associated with this account,
                and we’ll send instructions for resetting your password.
              </Typography>
            </Column>
            <Spacer height='sm' />

            <FormGroup style={{ alignSelf: 'center', width: isMobile || isTablet ? '90%' : '35%' }}>
              <TextInput<ForgotPasswordData>
                error={methods.formState.errors.birthDate !== undefined}
                helperText={methods.formState.errors.birthDate?.message?.toString()}
                defaultValue={forgotPasswordData?.birthDate}
                name='birthDate'
                label='DateOfBirth'
                type='dateInput'
                autoFocus
              />
              <Spacer height='sm' />
              <TextInput<ForgotPasswordData>
                error={methods.formState.errors.username !== undefined}
                helperText={methods.formState.errors.username?.message?.toString()}
                defaultValue={forgotPasswordData?.username}
                name='username'
                label='Email'
              />
            </FormGroup>
            <Spacer height='lg' />

            {isMobile || isTablet ? (
              <Column style={{ justifyContent: 'space-between' }}>
                <Button color='secondary' type='submit'>
                  Send Code
                </Button>
                <Spacer height='xs' />
                <Button onClick={navBack} variant='outlined' color='secondary'>
                  Cancel
                </Button>
              </Column>
            ) : (
              <Row style={{ justifyContent: 'space-between' }}>
                <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
                  Cancel
                </Button>
                <Button
                  className='md'
                  color='secondary'
                  type='submit'
                  style={{ alignSelf: 'flex-end' }}>
                  Send Code
                </Button>
              </Row>
            )}
          </Column>
        </form>
      </FormContent>
    </PageContentWithBackground>
  );
};

export default ForgotPassword;
