import { Grid } from '@mui/material';
import { Spacing } from '../../themes';
import NextLessonCard from './NextLessonCard';
import { Hooks } from '@3nickels/data-modules';
import UnitProgressCard from './components/UnitProgressCard';
import CourseCertificatePanel from './components/CourseCertificatePanel';
import CoachingSessionPanel from './components/CoachingSessionPanel';

const UniversityOverview: React.FC = () => {
  const units = Hooks.useUniversityUnits();
  const nextLesson = Hooks.useNextUniversityLesson();
  const coachingSessionUrl = Hooks.useCoachingSessionUrl();

  return (
    <Grid display='flex' flexDirection='column' rowSpacing={Spacing.lg} rowGap='20px'>
      <NextLessonCard />
      <Grid className='3NU-overview-content' display='flex' columnGap='20px'>
        <Grid className='unit-section' maxWidth='67%'>
          {units.map((unit) => (
            <UnitProgressCard unit={unit} selectedLesson={nextLesson} />
          ))}
        </Grid>
        <Grid display='flex' flexDirection='column' justifyContent='start' rowGap='20px'>
          <CoachingSessionPanel coachingSessionUrl={coachingSessionUrl} />
          <CourseCertificatePanel />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UniversityOverview;
